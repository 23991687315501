<template>
  <div class="mx-3 my-5">
    <v-row>
      <v-col>
        <div class="display-1 mb-3 success--text">
          Key Metrics
        </div>
        <div class="subtitle-2 mb-3 success--text">
          Excludes student projects and projects with onboarding data only
        </div>
      </v-col>
    </v-row>
    <v-tabs
      v-model="activeTab"
      background-color="shamrock"
      class="elevation-2"
      dark
      vertical
    >
      <v-tabs-slider />

      <v-tab key="chart">
        <v-icon left>
          mdi-chart-areaspline
        </v-icon>
        Chart view
      </v-tab>
      <v-tab key="data">
        <v-icon left>
          mdi-database
        </v-icon>
        Data view
      </v-tab>

      <v-tab-item
        :value="0"
        eager
      >
        <div
          class="pa-7 mx-auto"
          style="max-width: 1000px; min-height: 300px"
        >
          <ImpactChart
            v-if="years.length > 0"
            :key="activeTab"
            :years="years"
            :height="500"
            :width="300"
            legend-position="right"
            show-title
          />
          <div
            v-else
            style="text-align: center;"
          >
            <h1>No Data</h1>
          </div>
        </div>
      </v-tab-item>

      <v-tab-item :value="1">
        <v-row class="px-8 pt-6">
          <v-col cols="auto">
            <div style="transform: translateY(-3px); width: 300px; text-align: right;">
              <strong class="headline">{{ year }} year{{ year > 1 ? 's' : '' }} </strong>after project construction
            </div>
          </v-col>
          <v-col>
            <v-slider
              v-model="year"
              :min="1"
              :max="60"
              thumb-label
              thumb-size="30"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(stat, index) in thisYearStats"
            :key="index"
            cols="6"
            xl="4"
          >
            <KpiItem
              :title="stat.title"
              :value-color="stat.color"
            >
              <template v-slot:value>
                {{ stat.value }}<span style="font-size: 0.7em; margin-left: 7px;">mt</span>
              </template>
            </KpiItem>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import KpiItem from './KpiItem'
import ImpactChart from '@/components/atoms/ImpactChart'
import * as d3 from 'd3'

export default {
  name: 'ProjectOutputKpis',
  components: {
    KpiItem,
    ImpactChart
  },
  props: {
    years: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      year: 30,
      activeTab: 0
    }
  },
  computed: {
    thisYearStats () {
      if (!this.years) return {}
      let res = { ...this.years.find(x => x.year === this.year) }
      if (!res) {
        return {}
      }
      res.plants = res.plants * -1
      const colorFromVal = val => {
        if (val < 0) return 'red'
        if (val > 0) return 'shamrock'
        return 'metal'
      }
      const siFormat = d3.format('.3s')
      const stats = [
        {
          title: `Carbon source (embodied carbon)`,
          value: siFormat(res.materials),
          color: 'red'
        },
        {
          title: `Carbon sequestered (carbon sinks)`,
          value: siFormat(-res.plants),
          color: 'shamrock'
        },
        {
          title: `Carbon costs (operational carbon)`,
          value: siFormat(res.operations),
          color: 'red'
        },
        {
          title: `Carbon source + costs (total emissions)`,
          value: siFormat(res.emissions),
          color: 'red'
        },
        {
          title: `Delta of source + costs - sinks (how much carbon is sequestered beyond emissions?) `,
          value: siFormat(-res.overall),
          color: colorFromVal(-res.overall)
        }
      ]
      return stats
    }
  }
}
</script>

<style>

</style>
